import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'status'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'status'},

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'resetpassword', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'adminlogin', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'assets', loadChildren: () => import('app/modules/admin/assets/assets.module').then(m => m.AssetsModule)},
            {path: 'manage-qr', loadChildren: () => import('app/modules/admin/manage-qr/manage-qr.module').then(m => m.ManageQrModule)},
            {path: 'settings', loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule)},
            {path: 'status', loadChildren: () => import('app/modules/admin/status/status.module').then(m => m.StatusModule)},
            {path: 'users', loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule)},
            {path: 'retail-outlets', loadChildren: () => import('app/modules/admin/retail-outlets/retail-outlets.module').then(m => m.RetailOutletsModule)},
            {path: 'reports', loadChildren: () => import('app/modules/admin/reports/reports.module').then(m => m.ReportsModule)},
            {path: 'pricing', loadChildren: () => import('app/modules/admin/pricing/pricing.module').then(m => m.PricingModule)},
            {path: 'audit-log', loadChildren: () => import('app/modules/admin/audit-logs/audit-logs.module').then(m => m.AuditLogsModule)},
            {path: 'summary', loadChildren: () => import('app/modules/admin/summary/summary.module').then(m => m.SummaryModule)},
            {path: 'du-transactions', loadChildren: () => import('app/modules/admin/du-transactions/du-transactions.module').then(m => m.DuTransactionsModule)},
            {path: 'shifts', loadChildren: () => import('app/modules/admin/shifts/shifts.module').then(m => m.ShiftsModule)},
            {path: 'sales', loadChildren: () => import('app/modules/admin/sales/sales.module').then(m => m.SalesModule)},
            {path: 'alerts', loadChildren: () => import('app/modules/admin/alerts/alerts.module').then(m => m.AlertsModule)},
            {path: 'version', loadChildren: () => import('app/modules/admin/version/version.module').then(m => m.VersionModule)},
            {path: 'interlocks', loadChildren: () => import('app/modules/admin/intelock/intelock.module').then(m =>m.IntelockModule)},
            {path: 'tickets', loadChildren: () => import('app/modules/admin/tickets/tickets.module').then(m =>m.TicketsModule)}

        ]
    }
];
